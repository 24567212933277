<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="val in imgArray" :key="val.src">
        <van-image fit="fill" title="yxqlovezjf" :src="val.src" />
        <!-- <img object-fit="cover" src="https://img01.yzcdn.cn/vant/cat.jpeg" /> -->
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
const imaga = require("./images/a.jpg");
const imagb = require("./images/b.jpg");
const imagc = require("./images/c.jpg");
const imagd = require("./images/d.jpg");
const image = require("./images/e.jpg");
const imagf = require("./images/f.jpg");
console.log(imagf, "-----1");
export default {
  data() {
    return {
      imgArray: [
        { src: imaga },
        { src: imagb },
        { src: imagc },
        { src: imagd },
        { src: image },
        { src: imagf },
      ],
    };
  },
};
</script>

<style scoped>
.my-swipe {
  width: 100%;
  /* height: 375px; */
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  width: 375px;
  /* height: 375px; */
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #39a9ed; */
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
</style>
